import { CourseDetails } from './Course';

export enum WorkspaceType {
  class = 'class',
  team = 'team',
  individual = 'individual',
}

export interface Workspace {
  courseDetails: CourseDetails;
  courseId: string;
  instructorIds: string[];
  name: string;
  taIds: string[];
  type: WorkspaceType;
  userIds: string[];
}
