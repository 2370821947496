// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: 'AIzaSyAa769oy13h_ufrupFBqMR4X0Xg0GEckQ0',
    // authDomain: 'codesync-staging.firebaseapp.com',
    // projectId: 'codesync-staging',
    // storageBucket: 'codesync-staging.appspot.com',
    // messagingSenderId: '631555900312',
    // appId: '1:631555900312:web:61b6d4f2cd743807d0a1ed',
    apiKey: 'AIzaSyA8P3TvxMiv7egfiXdXa2tGuGscRgicSSo',
    authDomain: 'codesync-prod-2.firebaseapp.com',
    projectId: 'codesync-prod-2',
    storageBucket: 'codesync-prod-2.appspot.com',
    messagingSenderId: '512491843129',
    appId: '1:512491843129:web:7cebe96dadcf7c962ba7e8',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
