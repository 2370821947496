<mat-card class="mx-auto max-w-md mt-10">
    <app-user-form></app-user-form>
</mat-card>

<mat-card class="mx-auto max-w-md mt-10">
  <h1>Users</h1>

  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let user of userList">
      <a routerLink="/users/{{user.id}}">
        {{ user.displayName }} - {{ user.email }}
      </a>
    </mat-list-item>
  </mat-list>
</mat-card>
