import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  userSignedIn = false;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.userSignedIn().subscribe((signedIn) => {
      this.userSignedIn = signedIn;
    });
  }

  signOut() {
    this.authService.signOut();
  }
}
