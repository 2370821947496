import { CourseDetails } from './Course';
import { UserDetails } from './User';

export enum RegistrationRole {
  instructor = 'instructor',
  ta = 'ta',
  student = 'student',
}

export enum RegistrationType {
  course = 'course',
  project = 'project',
}

export interface Registration {
  type: RegistrationType;
  userDetails: UserDetails;
  userId: string;
}

export interface CourseRegistration extends Registration {
  courseDetails: CourseDetails;
  courseId: string;
  role: RegistrationRole;
}

export interface CourseRegistrationId extends CourseRegistration {
  id: string;
}
