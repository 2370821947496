import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from '@angular/fire/auth-guard';

import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { HomeComponent } from './views/home/home.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { CoursesComponent } from './views/courses/courses.component';
import { CourseDetailComponent } from './views/course-detail/course-detail.component';
import { UsersComponent } from './views/users/users.component';
import { UserDetailComponent } from './views/user-detail/user-detail.component';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['sign-in']);
const redirectSignedInToHome = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
    ...canActivate(redirectSignedInToHome),
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: '',
    component: HomeComponent,
    ...canActivate(redirectUnauthorizedToSignIn),
    // ...canActivate(adminOnly),
  },
  {
    path: 'courses',
    component: CoursesComponent,
    ...canActivate(redirectUnauthorizedToSignIn),
  },
  {
    path: 'courses/:courseId',
    component: CourseDetailComponent,
    ...canActivate(redirectUnauthorizedToSignIn),
  },
  {
    path: 'users',
    component: UsersComponent,
    ...canActivate(redirectUnauthorizedToSignIn),
  },
  {
    path: 'users/:userId',
    component: UserDetailComponent,
    ...canActivate(redirectUnauthorizedToSignIn),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
