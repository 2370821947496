import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { CourseRegistration, Registration } from '../models/Registration';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private registrationsCollection: AngularFirestoreCollection<Registration>;

  constructor(private db: AngularFirestore) {
    this.registrationsCollection = db.collection<Registration>('registrations');
  }

  async createCourseRegistrations(registrations: CourseRegistration[]) {
    const p = registrations.map((reg) => {
      return this.db
        .collection('registrations', (ref) =>
          ref
            .where('userId', '==', reg.userId)
            .where('courseId', '==', reg.courseId)
        )
        .get()
        .toPromise()
        .then((querySnap) => {
          if (!querySnap.empty) {
            const e = new Error('Already exists');
            console.error(e);
            throw e;
          } else {
            return;
          }
        });
    });

    return Promise.all(p).then(() => {
      // All is good
      registrations.forEach((reg) => {
        this.registrationsCollection.add(reg);
      });
    });
  }
}
