import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Course } from 'src/app/models/Course';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.scss'],
})
export class CourseFormComponent implements OnInit {
  courseForm = this.fb.group({
    code: [''],
    name: [''],
    semester: [''],
    yearLabel: [''],
    allowIndividualWorkspaces: [false],
  });

  constructor(private fb: FormBuilder, private courseService: CourseService) {}

  ngOnInit(): void {}

  onSubmit() {
    const code = this.courseForm.get('code').value;
    const name = this.courseForm.get('name').value;
    const semester = this.courseForm.get('semester').value;
    const year = +this.courseForm.get('yearLabel').value;
    const allowIndividualWorkspaces = this.courseForm.get(
      'allowIndividualWorkspaces'
    ).value;

    this.courseService
      .createCourse(code, name, semester, year, allowIndividualWorkspaces)
      .then(() => {
        this.courseForm.reset();
      });
  }
}
