<div class="mt-10 space-y-10">
  <mat-card class="mx-auto max-w-md" *ngIf="course">
    <h1>Course: {{course.id}}</h1>

    <form [formGroup]="courseForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-col">
        <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input matInput type="text" placeholder="" formControlName="code" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput type="text" placeholder="" formControlName="name" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Semester</mat-label>
          <input
            matInput
            type="text"
            placeholder=""
            formControlName="semester"
          />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Year</mat-label>
          <input
            matInput
            type="text"
            placeholder=""
            formControlName="yearLabel"
          />
        </mat-form-field>
      </div>

      <button mat-raised-button color="primary">Update</button>
    </form>
  </mat-card>

  <mat-card class="mx-auto max-w-md" *ngIf="course">
    <h1>Register users</h1>

    <table mat-table [dataSource]="filteredUserList" style="width: 100%">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Display name</th>
        <td mat-cell *matCellDef="let element">{{element.displayName}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{input.value}}"
        </td>
      </tr>
    </table>

    <mat-form-field class="mt-5" appearance="fill">
      <mat-label>Choose a role</mat-label>
      <mat-select [formControl]="role">
        <mat-option value="student">Student</mat-option>
        <mat-option value="instructor">Instructor</mat-option>
        <mat-option value="ta">TA</mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <button mat-raised-button color="primary" (click)="registerUsers()">
        Register users
      </button>

      <p class="mt-5 text-red-500" *ngIf="hasRegistrationError">
        User(s) are already registered in this course.
      </p>
    </div>
  </mat-card>
</div>
