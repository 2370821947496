import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs/';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private auth: AngularFireAuth, private router: Router) {
    this.userSignedIn().subscribe((user) => {
      console.log('AUTH STAGE CHANGED');

      if (user) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/sign-in']);
      }
    });
  }

  async signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    this.auth.signOut();
  }

  userSignedIn(): Observable<boolean> {
    return this.auth.authState.pipe(
      map((user) => {
        if (user) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
