import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SignInComponent } from './views/sign-in/sign-in.component';
import { HomeComponent } from './views/home/home.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CoursesComponent } from './views/courses/courses.component';
import { UsersComponent } from './views/users/users.component';
import { CourseDetailComponent } from './views/course-detail/course-detail.component';
import { CourseFormComponent } from './components/course-form/course-form.component';
import { UserDetailComponent } from './views/user-detail/user-detail.component';
import { UserFormComponent } from './components/user-form/user-form.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HomeComponent,
    ForgotPasswordComponent,
    ToolbarComponent,
    CoursesComponent,
    UsersComponent,
    CourseDetailComponent,
    CourseFormComponent,
    UserDetailComponent,
    UserFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,

    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTableModule,
    MatCheckboxModule,
    MatSlideToggleModule,
  ],
  providers: [{ provide: REGION, useValue: 'us-west2' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
