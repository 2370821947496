<mat-card class="container mx-auto max-w-md mt-10">
  <h1>Sign in</h1>

  <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
    <div class="flex flex-col">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="" formControlName="email" />
        <!-- <mat-hint>Hint</mat-hint> -->
        <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          placeholder=""
          formControlName="password"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>

      <div class="flex flex-col space-y-2">
        <a
          class="self-start"
          mat-flat-button
          [routerLink]="['/forgot-password']"
        >
          Forgot your password?
        </a>

        <button mat-raised-button color="primary">Sign in</button>
      </div>
    </div>
  </form>

  <div class="mt-5 font-light text-red-500">{{ errorMessage }}</div>
</mat-card>
