import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CourseId } from 'src/app/models/Course';
import {
  CourseRegistration,
  RegistrationType,
  RegistrationRole,
} from 'src/app/models/Registration';
import { UserId } from 'src/app/models/User';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss'],
})
export class CourseDetailComponent implements OnInit {
  course: CourseId;

  courseForm = this.fb.group({
    code: [''],
    name: [''],
    semester: [''],
    yearLabel: [''],
  });

  userList: UserId[] = [];

  selection = new SelectionModel<UserId>(true, []);

  filteredUserList: Observable<UserId[]>;

  filterInput = new FormControl();

  displayedColumns: string[] = ['select', 'displayName', 'email'];

  role = new FormControl(RegistrationRole.student);

  hasRegistrationError = false;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private registrationService: RegistrationService,
    public userService: UserService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getCourse();
    this.getUsers();

    // this.filteredUserList = this.filterInput.valueChanges.pipe(
    //   startWith(''),
    //   map((value) => this.filterUserList(value))
    // );
  }

  getCourse(): void {
    const courseId = this.route.snapshot.paramMap.get('courseId');
    this.courseService.getCourse(courseId).subscribe((course) => {
      this.course = course;
      this.courseForm.controls.code.setValue(course.code);
      this.courseForm.controls.name.setValue(course.name);
      this.courseForm.controls.semester.setValue(course.semester);
      this.courseForm.controls.yearLabel.setValue(course.year);
    });
  }

  onSubmit() {
    this.course.code = this.courseForm.get('code').value;
    this.course.name = this.courseForm.get('name').value;
    this.course.semester = this.courseForm.get('semester').value;
    this.course.year = +this.courseForm.get('yearLabel').value;

    this.courseService.updateCourse(this.course).then(() => {
      console.log('UPDATED');
    });
  }

  getUsers() {
    this.filteredUserList = this.userService.getUsers().pipe(
      map((data) => {
        this.userList = data;
        return data;
      })
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.userList.forEach((row) => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UserId): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.displayName
    }`;
  }

  registerUsers() {
    const selectedUsers = this.selection.selected;

    this.hasRegistrationError = false;

    if (selectedUsers.length < 1) {
      return;
    }

    const regs: CourseRegistration[] = [];
    selectedUsers.forEach((user: UserId) => {
      const reg: CourseRegistration = {
        courseDetails: {
          code: this.course.code,
          name: this.course.name,
          semester: this.course.semester,
          year: this.course.year,
        },
        courseId: this.course.id,
        role: this.role.value,
        type: RegistrationType.course,
        userDetails: {
          displayName: user.displayName,
          email: user.email,
        },
        userId: user.id,
      };

      regs.push(reg);
    });

    this.registrationService
      .createCourseRegistrations(regs)
      .then(() => {
        this.selection.clear();
        this.role.reset();
      })
      .catch((err) => {
        this.hasRegistrationError = true;
      });
  }

  private filterUserList(value: string): UserId[] {
    const filterValue = value.toLowerCase();

    return this.userList.filter(
      (user) => user.displayName.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
