import { Component, OnInit } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  signInForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  errorMessage = '';

  constructor(private fb: FormBuilder, private auth: AuthService) {}

  ngOnInit(): void {}

  onSubmit() {
    this.errorMessage = '';

    if (!this.signInForm.valid) {
      return;
    }

    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;

    this.auth
      .signIn(email, password)
      .then(() => {
        this.signInForm.reset();
        this.signInForm.controls.email.setErrors(null);
        this.signInForm.controls.password.setErrors(null);
      })
      .catch((err) => {
        if (err.code === 'auth/too-many-requests') {
          this.errorMessage =
            'Account temporarily disabled due to too many failed login attempts';
        } else {
          this.errorMessage = 'Incorrect username/password combination';
        }
      });
  }
}
