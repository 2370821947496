<h1>Create a new course</h1>

<form class="space-y-5" [formGroup]="courseForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-col">
    <mat-form-field appearance="fill">
      <mat-label>Code</mat-label>
      <input matInput type="text" placeholder="" formControlName="code" />
      <!-- <mat-hint>Hint</mat-hint> -->
      <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput type="text" placeholder="" formControlName="name" />
      <!-- <mat-hint>Hint</mat-hint> -->
      <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Semester</mat-label>
      <input matInput type="text" placeholder="" formControlName="semester" />
      <!-- <mat-hint>Hint</mat-hint> -->
      <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Year</mat-label>
      <input matInput type="text" placeholder="" formControlName="yearLabel" />
      <!-- <mat-hint>Hint</mat-hint> -->
      <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
    </mat-form-field>

    <mat-slide-toggle formControlName="allowIndividualWorkspaces"
      >Allow individual workspaces</mat-slide-toggle
    >
  </div>

  <button mat-raised-button color="primary">Create</button>
</form>
