<mat-card class="mx-auto max-w-md mt-10">
  <app-course-form></app-course-form>
</mat-card>

<mat-card class="mx-auto max-w-md mt-10">
  <h1>Courses</h1>

  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let course of courseList">
      <a routerLink="/courses/{{course.id}}">
        {{ course.code }}: {{ course.name }} {{ course.semester }} {{
        course.year}}
      </a>
    </mat-list-item>
  </mat-list>
</mat-card>
