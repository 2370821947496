import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import { User } from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersCollection: AngularFirestoreCollection<User>;

  constructor(private db: AngularFirestore, private fns: AngularFireFunctions) {
    this.usersCollection = db.collection<User>('users');
  }

  getUsers() {
    return this.usersCollection.valueChanges({ idField: 'id' });
  }

  createUser(displayName: string, email: string) {
    const createUserAccount = this.fns.httpsCallable('user-createAccount');
    const data = {
      displayName,
      email,
    };

    return createUserAccount(data).pipe(
      map((r) => {
        console.log(r);
      })
    );
  }
}
