<mat-toolbar color="primary">
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span>Codesync</span>

  <ng-container *ngIf="userSignedIn">
    <span class="example-spacer" style="flex: 1 1 auto"></span>

    <a mat-button [routerLink]="['/']">Home</a>

    <a mat-button [routerLink]="['/courses']">Courses</a>

    <a mat-button [routerLink]="['/users']">Users</a>

    <button mat-button (click)="signOut()">Sign out</button>
  </ng-container>
</mat-toolbar>
