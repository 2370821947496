import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';

import { Course, CourseId } from '../models/Course';
import { Workspace, WorkspaceType } from '../models/Workspace';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private coursesCollection: AngularFirestoreCollection<Course>;
  private workspacesCollection: AngularFirestoreCollection<Workspace>;

  constructor(private db: AngularFirestore) {
    this.coursesCollection = db.collection<Course>('courses');
    this.workspacesCollection = db.collection<Workspace>('workspaces');
  }

  createCourse(
    code: string,
    name: string,
    semester: string,
    year: number,
    allowIndividualWorkspaces: boolean
  ) {
    const course = {
      code,
      createdAt: firebase.firestore.Timestamp.now(),
      name,
      semester,
      year,
      allowIndividualWorkspaces,
    };

    return this.coursesCollection.add(course).then((docRef) => {
      // create the class workspace
      const classWorkspace: Workspace = {
        courseDetails: {
          code: course.code,
          name: course.name,
          semester: course.semester,
          year: course.year,
        },
        courseId: docRef.id,
        instructorIds: [],
        name: 'Classwork',
        taIds: [],
        type: WorkspaceType.class,
        userIds: [],
      };

      return this.workspacesCollection.add(classWorkspace);
    });
  }

  getCourses() {
    return this.coursesCollection.valueChanges({ idField: 'id' });
  }

  getCourse(courseId: string) {
    const courseDoc: AngularFirestoreDocument<Course> = this.db.doc<Course>(
      `courses/${courseId}`
    );
    return courseDoc.valueChanges({ idField: 'id' });
  }

  updateCourse(course: CourseId) {
    const courseDoc: AngularFirestoreDocument<Course> = this.db.doc<Course>(
      `courses/${course.id}`
    );

    return courseDoc.update({
      code: course.code,
      name: course.name,
      semester: course.semester,
      year: course.year,
    });
  }
}
