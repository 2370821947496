import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  userForm = this.fb.group({
    displayName: [''],
    email: [''],
  });

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {}

  onSubmit() {
    const displayName = this.userForm.get('displayName').value;
    const email = this.userForm.get('email').value;

    this.userService
      .createUser(displayName, email)
      .toPromise()
      .then(() => {
        this.userForm.reset();
      });
  }
}
