import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'admin-web-app';

  userSignedIn = false;

  constructor(private authService: AuthService) {
    this.authService.userSignedIn().subscribe((signedIn) => {
      this.userSignedIn = signedIn;
    });
  }

  signOut() {
    this.authService.signOut();
  }
}
