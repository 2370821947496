<h1>Create a new user</h1>

<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-col">
    <mat-form-field appearance="fill">
      <mat-label>Display name</mat-label>
      <input matInput type="text" placeholder="" formControlName="displayName" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput type="email" placeholder="" formControlName="email" />
    </mat-form-field>
  </div>

  <button mat-raised-button color="primary">Create</button>
</form>
